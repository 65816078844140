const svarbase = document.querySelector('body.post-type-archive-sprakspoersmal');

if(svarbase) {
    let currentPage = 1;
    let pagination = svarbase.querySelector('.pagination') ?? null;
    let paginationClicked = false;

    // SEARCH
    let searchForm = svarbase.querySelector('.search-questions');
    let inputField = searchForm.querySelector('input');
    let deleteBtn = searchForm.querySelector('form svg');
    let prevSearch = inputField.value.toLowerCase() ?? '';

    const toggleChildren = svarbase.querySelectorAll('.toggle-children');

    if(inputField.value != '') {
        deleteBtn.classList.add('show');
    }

    inputField.addEventListener('keyup', function (e) {
        let searchText = e.target.value.toLowerCase();
        if(searchText != '') {
            deleteBtn.classList.add('show');
        }
        else {
            deleteBtn.classList.remove('show');
        }
    });

    deleteBtn.addEventListener('click', function(e) {
        inputField.value = '';
        prevSearch = '';
        deleteBtn.classList.remove('show');
        inputField.setAttribute('data-url', '?' + serializeOptions(toggleFieldOption('sok', '', true, false, true)));
        let href = inputField.getAttribute('data-url');
        currentPage = 1;
        paginationClicked = false;
        updateOptionList(href, 'sok', '', true, false, true);
    });
    searchForm.addEventListener('submit', function(e) {
        e.preventDefault();
        e.stopPropagation();
    });
    searchForm.querySelector('button.search-submit-button').addEventListener('click', function(e) {
        e.preventDefault();
        if(inputField.value != prevSearch) {
            inputField.setAttribute('data-url', '?' + serializeOptions(toggleFieldOption('sok', inputField.value.toLowerCase(), true, false, true)));
            let href = inputField.getAttribute('data-url');
            currentPage = 1;
            paginationClicked = false;
            updateOptionList(href, 'sok', inputField.value.toLowerCase(), true, false, true);            
        }
        prevSearch = inputField.value.toLowerCase();
    });

    //FILTER
    let categoriesWrapper = svarbase.querySelector('.categories');
    let filterBtn = categoriesWrapper.querySelector('button');

    if(window.innerWidth >= 1024) {
        categoriesWrapper.classList.add('open');
        setTimeout(function() {
            categoriesWrapper.classList.add('anim');
        }, 10);
    }

    filterBtn.addEventListener('click', function(e) {
        e.preventDefault();

        if(!categoriesWrapper.classList.contains('open')) {
            categoriesWrapper.classList.add('open');
        }
        else {
            categoriesWrapper.classList.remove('open');
        }
    });

    categoriesWrapper.querySelectorAll('.category-term input').forEach(function(term, i) {
        term.addEventListener('change', function() {
            let href = term.getAttribute('data-url');
            let slug = term.getAttribute('id');
            let catOptions = categoriesWrapper.querySelector('.terms-wrapper');
            let key = catOptions.getAttribute('data-option');
            currentPage = 1;
            paginationClicked = false;
            updateOptionList(href, key, slug, false, false, true);
        });
    });

    toggleChildren.forEach(function(toggle) {
        toggle.addEventListener('click', function(e) {
            e.preventDefault();

            const parent = toggle.parentElement.parentElement;
            if (parent.classList.contains('open')) {
                parent.classList.remove('open');
            } else {
                parent.classList.add('open');
            }
        })
    })

    setTermButtons();

    function setTermButtons() {
        let questions = svarbase.querySelectorAll('.question-dropdown');
        questions.forEach(function(question, i) {
            question.querySelectorAll('.terms-wrapper button').forEach(function(button, j) {
                button.addEventListener('click', function(e) {
                    e.preventDefault();
                    let href = button.getAttribute('data-url');
                    let slug = button.getAttribute('data-slug');
                    categoriesWrapper.querySelectorAll('.category-term input').forEach(function(term, i) {
                        term.checked = false;
                    });
                    categoriesWrapper.querySelector('input#' + slug).checked = true;  
                    currentPage = 1;
                    paginationClicked = true;

                    inputField.value = '';
                    deleteBtn.classList.remove('show');
                    inputField.setAttribute('data-url', '?' + serializeOptions(toggleFieldOption('sok', '', true, true, true)));
                    
                    updateOptionList(href, 'kategori', slug, true, true, true);
                });
            });
        });
    }
    
    function updateOptionList(href, key, value, isSingle, isTagClicked, isReset) {
        
        // Update history state
        if (window.history.pushState) {
            window.history.pushState({}, '', href);
        }

        // Update the options list
        let optionList = categoriesWrapper;
        let newOptions;
        if(isSingle) {
            newOptions = toggleFieldOption(key, value, isSingle, isTagClicked, isReset);
        }
        else {
            newOptions = toggleFieldOption(key, value, isSingle, isTagClicked, isReset);
        }

        optionList.setAttribute('data-options', serializeOptions(newOptions));

        inputField.setAttribute('data-url', '?' + serializeOptions(newOptions));
    
        // Update all option hrefs to match the new selection
        optionList.querySelectorAll('.terms-wrapper').forEach(function(option, i) {
            let key = option.getAttribute('data-option');
            
            option.querySelectorAll('input').forEach(function(term, j) {
                let slug = term.getAttribute('id');
                term.setAttribute('data-url', '?' + serializeOptions(toggleFieldOption(key, slug, false, false, true)));
            });
        });

        getQuestions();
    }

    
    //PAGINATION
    function setPagination() {
        svarbase.querySelectorAll('.pagination .page-number').forEach((item) => {
          item.addEventListener('click', function(e) {
            e.preventDefault();
            currentPage = parseInt(item.getAttribute('data-slug'));
            paginationClicked = true;
            let href = item.getAttribute('data-url');
            updateOptionList(href, 'page', currentPage, true, false, false);

          });
        });
      
        svarbase.querySelectorAll('.pagination li.prev a').forEach((item) => {
          item.addEventListener('click', function(e) {
            e.preventDefault();
            currentPage -= 1;
            paginationClicked = true;
            let href = item.getAttribute('data-url');
            updateOptionList(href, 'page', currentPage, true, false, false);
          });
        });
      
        svarbase.querySelectorAll('.pagination li.next a').forEach((item) => {
          item.addEventListener('click', function(e) {
            e.preventDefault();
            currentPage += 1;
            paginationClicked = true;
            let href = item.getAttribute('data-url');
            updateOptionList(href, 'page', currentPage, true, false, false);
          });
        });
      
        if(currentPage == svarbase.querySelectorAll('.pagination li:not(.next, .prev)').length) {
            svarbase.querySelector('.pagination li.next').classList.add('disabled');
            svarbase.querySelector('.pagination li.next button').classList.add('disabled');
        }
    }
    if(pagination) {
        setPagination();
    }

    function getQuestions() {                    
        window.scroll({top: 0, left: 0});

        svarbase.querySelector('.svarbase-wrapper').innerHTML = '';

        let spinner = document.createElement('div');
        spinner.className = 'loading-spinner';
        let ring = document.createElement('div');
        ring.className = 'lds-ring';
        let children = [];
        for(let i=0; i<5; i++) {
            children[i] = document.createElement('div');
            ring.appendChild(children[i]);
        }
        spinner.append(ring);
        svarbase.querySelector('.svarbase-wrapper').append(spinner); 

        let selectedCats = parseFieldOptions(categoriesWrapper.getAttribute('data-options'), false, true);
        let data = new FormData();
        data.append('action', 'get_posts');
        data.append('page', currentPage);
        data.append('categories', selectedCats['kategori']);
        data.append('search', inputField.value.toLowerCase());
        $.ajax({
            type: 'POST',
            url: sprakradet_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                svarbase.querySelector('.svarbase-wrapper').innerHTML = res;
                setTermButtons();
                setDropdownEvents();
                pagination = svarbase.querySelector('.pagination');
                if (pagination) {
                  setPagination();
                }
            }
        });
    }

    function parseFieldOptions(str, isTagClicked, isReset) {
        let components = str.split('&');
    
        let pageComponents = components[0].split('=');
        let pages = 1;
        if(!isReset) {
            pages = pageComponents[1].length > 0 ? pageComponents[1].split(',') : [];
        }
    
        let catComponents = components[1].split('=');
        let cats = catComponents[1].length > 0 ? catComponents[1].split(',') : [];
    
        let searchComponents = components[2].split('=');
        let search = '';
        if(!isTagClicked) {
            search = searchComponents[1].length > 0 ? searchComponents[1].split(',') : [];
        }
        
        return {
            page: pages,
            kategori: cats,
            sok: search
        }
    }

    function toggleFieldOption(type, option, isSingle, isTagClicked, isReset) {
        let options = parseFieldOptions(categoriesWrapper.getAttribute('data-options'), isTagClicked, isReset);

        let list = options[type];

        if (isSingle) {
            list = [option];
        } else {
            const index = list ? list.indexOf('' + option) : -1;
            if (index !== -1) {
                list.splice(index, 1);
            } else {
                list.push(option);
            }
        }
    
        options[type] = list;
        return options;
    }

    function serializeOptions(options) {
        let keys = Object.keys(options);
        let components = [];
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if(key == 'sok') {
                let value = options[key][options[key].length - 1] ?? '';
                components.push(key + '=' + value);
            }
            else if(key == 'page') {
                let value = options[key][options[key].length - 1] ?? 1;
                components.push(key + '=' + value);
            }
            else {
                components.push(key + '=' + options[key].join(','));
            }
        }
        return components.join('&');
    }

    //DROPDOWNS
    function setDropdownEvents() {
        let dropdownsWrapper = svarbase.querySelector('.svarbase-wrapper');
        let dropdowns = dropdownsWrapper.querySelectorAll('.question-dropdown');
        if(dropdowns.length > 0) {
            dropdowns.forEach(element => {
                let dropdownToggle = element.querySelector('.dropdown-toggle');
                dropdownToggle.addEventListener('click', function() {
                    if(!element.classList.contains('expanded')){
                        element.classList.add('expanded');
                        dropdownToggle.setAttribute('aria-expanded', true);
                    }
                    else {
                        element.classList.remove('expanded');
                        dropdownToggle.setAttribute('aria-expanded', false);
                    }
                });
                dropdownToggle.querySelector('a').addEventListener('click', function(e) {
                    e.stopPropagation();
                })
            });
        }
    }
    let dropdownsWrapper = svarbase.querySelector('.svarbase-wrapper');
    let dropdowns = dropdownsWrapper.querySelectorAll('.question-dropdown');
    if(dropdowns.length > 0) {
        setDropdownEvents();
    }
}