import './_body-view-width';

import "./header.js";
import "./anchor-menu.js";
import "./_scroll-to.js";
import "./single.js";
import "./search.js";
import "./dropdown-list.js";
import "./bottom-form.js";
import "./_table-with-search.js"
import "./_page-svarbase.js";
import "./_notice-message.js";
import "./_lazy-image.js";

let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if(skipHeader != undefined) {
    skipHeader.addEventListener('focusin', function (e) {
        this.classList.add('focused');  
    });
    skipHeader.addEventListener('focusout', function (e) {
        this.classList.remove('focused');  
    });
}
import './_single-sprakspoersmal.js';