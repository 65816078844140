const message = document.querySelector('.notice-message');

if(message) {
    let dismissed = sessionStorage.getItem("dismissed");
    let closeBtn = message.querySelector('button.close-msg-btn');
    if(!dismissed){
        message.classList.remove("hide");
    }

    closeBtn.addEventListener('click', function() {
        message.classList.add("hide");
        sessionStorage.setItem("dismissed", true);
    });
}
else {
    sessionStorage.removeItem("dismissed");
}