import { scrollToAnchor } from "./_scroll-to";

const anchorMenu = document.querySelector('nav.anchor-menu');

document.addEventListener("DOMContentLoaded", function() {
    setTimeout(function(){

    if(anchorMenu) {
    let firstElem = anchorMenu.nextElementSibling;
    let all_headings = document.querySelectorAll('.wp-block-heading');
    let headerHeight = document.querySelector('header').getBoundingClientRect().height;
    let firstElemOffset = firstElem.offsetTop;
    let headings = [];
    all_headings.forEach(function(heading, index) {
        if(heading.getAttribute('id') != null) {
            headings.push(heading);
        }
    });
    if(headings.length < 1) {
        anchorMenu.style.display = 'none';
    }
    else {
        headings.forEach(function(heading, index) {
            anchorMenu.querySelector('ul').innerHTML += '<li class="slug-' + heading.getAttribute('id') + '"><a href="#' + heading.getAttribute('id') + '">' + heading.innerText + '</a></li>';
        });
        anchorMenu.querySelectorAll('a').forEach(function(link, index){
            link.addEventListener('click', function(e) {
                scrollToAnchor(this.href, e, 100);
            });
        });
        let initialLeftPos;
        if(window.innerWidth >= 1488) {
            initialLeftPos = ((window.innerWidth - 1488)/2);
            anchorMenu.style.left = initialLeftPos + 'px';
            anchorMenu.style.maxWidth = 366 + 'px';
        }
        else if(window.innerWidth >= 1024) {
            initialLeftPos = anchorMenu.getBoundingClientRect().left;
        }
        let initialWidth = anchorMenu.getBoundingClientRect().width;
        let topSet = false;
        let anchorTop = firstElemOffset;
    
        anchorMenu.style.top = firstElemOffset + 'px';
        window.addEventListener('resize', function() {
            if(window.innerWidth >= 1488) {
                initialLeftPos = ((window.innerWidth - 1488)/2);
                anchorMenu.style.maxWidth = 366 + 'px';
            }
            else if(window.innerWidth >= 1024) {
                initialLeftPos = anchorMenu.getBoundingClientRect().left;
                initialWidth = anchorMenu.getBoundingClientRect().width;
            }
            if(window.innerWidth >= 1024) {
                anchorMenu.style.top = firstElemOffset + 'px';
                anchorMenu.style.position = 'absolute';
                anchorMenu.style.left = initialLeftPos + 'px';
                window.addEventListener('scroll', anchorOnScroll);
            }
            else {
                window.removeEventListener('scroll', anchorOnScroll);
                anchorMenu.style.position = 'static';
                anchorMenu.style.top = '0';
                anchorMenu.style.maxWidth = '100%';
            }
        });
    
        let adminBarHeight = 0;
        let adminBar = document.querySelector('#wpadminbar');
        if(adminBar) {
            adminBarHeight = 32;
        }
    
        let headingOffset;
        let allowedSiblings = ['p', 'ul', 'ol', 'section.link-columns', 'section.dropdown-list', '.factbox', 'blockquote', '.wp-block-table'];
        let lastHeadingSiblings = getNextUntil(headings[headings.length - 1], allowedSiblings);
        let lastParagraph = lastHeadingSiblings[lastHeadingSiblings.length - 1] ?? headings[headings.length - 1];

        if(window.innerWidth >= 1024) {
            window.addEventListener('scroll', anchorOnScroll);
        }
    
        function anchorOnScroll() {
            let scroll = window.scrollY;
    
            if(scroll >= (firstElemOffset + headerHeight - 64 + adminBarHeight) && scroll < (lastParagraph.offsetTop + lastParagraph.offsetHeight - anchorMenu.offsetHeight + 64 + adminBarHeight)) {
                anchorMenu.style.position = 'fixed';
                anchorMenu.style.maxWidth = initialWidth + 'px';
                anchorMenu.style.top = '4rem';
                anchorMenu.style.left = initialLeftPos + 'px';
                anchorMenu.style.bottom = 'unset';
            }
            else if(scroll > (lastParagraph.offsetTop + lastParagraph.offsetHeight - anchorMenu.offsetHeight + 64 + adminBarHeight) && scroll < (lastParagraph.offsetTop + lastParagraph.offsetHeight + anchorMenu.offsetHeight + 64 + adminBarHeight)) { 
                anchorMenu.style.position = 'absolute';
                anchorMenu.style.left = initialLeftPos + 'px';
                if(!topSet) {
                    topSet = true;
                    anchorTop = scroll - headerHeight + 64 - adminBarHeight;
                }
                anchorMenu.style.top = anchorTop + 'px';
            }
            else if(scroll < (firstElemOffset + headerHeight - 64 + adminBarHeight)) {
                anchorMenu.style.position = 'absolute';
                anchorMenu.style.top = firstElemOffset + 'px';
                anchorMenu.style.bottom = 'unset';
                anchorMenu.style.left = initialLeftPos + 'px';
                if(anchorMenu.querySelector('li.active')) {
                    anchorMenu.querySelector('li.active').classList.remove('active');
                }
            }
        
            headings.forEach(function(heading, index) {
                if(index == 0) {
                    headingOffset = 150;
                }
                else {
                    headingOffset = 150;
                }
                if (!anchorMenu.querySelector('li.slug-' + heading.getAttribute('id')).classList.contains('active') && (heading.getBoundingClientRect().top < headingOffset)) {
                    if(anchorMenu.querySelector('li.active')) {
                        anchorMenu.querySelector('li.active').classList.remove('active');
                    }
                    anchorMenu.querySelector('li.slug-' + heading.getAttribute('id')).classList.add('active');
                }
            });
        }
    
        function getNextUntil (elem, selectors) {
            let siblings = [];
            let next = elem.nextElementSibling;
        
            while (next) {
                if (selectors && next.matches(selectors)){
                    siblings.push(next);
                }
                next = next.nextElementSibling
            }
            return siblings;
        };
    }
}
}, 50);

});