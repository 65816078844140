const search = document.querySelector('body.search');

if(search) {
    let inputField = search.querySelector('input');
    let deleteBtn = search.querySelector('form svg');
    let inputValue = inputField.value;
    inputField.focus();
    inputField.value = '';
    inputField.value = inputValue;

    if(inputField.value != '') {
        deleteBtn.classList.add('show');
    }

    inputField.addEventListener('keyup', function (e) {
        let searchText = e.target.value.toLowerCase();
        if(searchText != '') {
            deleteBtn.classList.add('show');
        }
        else {
            deleteBtn.classList.remove('show');
        }
    });

    deleteBtn.addEventListener('click', function(e) {
        inputField.value = '';
        deleteBtn.classList.remove('show');
    });
}