const tableBlocks = document.querySelectorAll('section.table-with-search');

if(tableBlocks.length > 0) {
    tableBlocks.forEach((block, index) => {
        let titles = block.querySelectorAll('.table-title');
        if(titles.length > 0 && (block.querySelector('ul.table-shortcuts'))) {
            titles.forEach(function(title, index) {
                block.querySelector('ul.table-shortcuts').innerHTML += '<li class="slug-' + title.getAttribute('id') + '"><a href="#' + title.getAttribute('id') + '">' + title.innerText + '</a><span>|</span></li>';
            });
        }
        let searchInput = block.querySelector('.search-table-input');
        let deleteBtn = block.querySelector('.input-wrapper svg');
        let tables = block.querySelectorAll('.wp-block-table')
        let cells = block.querySelectorAll('td');
        let noResultsElem = document.createElement('div');
        let searchText = '';
        noResultsElem.className = 'u-grid';
        noResultsElem.innerHTML = '<p class="no-results">Ingen resultater</p>'

        if(searchInput) {
            searchInput.addEventListener('keyup', function(e) {
                searchText = e.target.value.toLowerCase();
                if(searchText == '') {
                    deleteBtn.classList.remove('show');
                    filterTables(searchText);
                }
                else {
                    deleteBtn.classList.add('show');
                }
            });
            deleteBtn.addEventListener('click', function(e) {
                searchInput.value = '';
                searchText = '';
                deleteBtn.classList.remove('show');
                filterTables(searchText);
            });
            block.querySelector('form.search-page').addEventListener('submit', function(e) {
                e.preventDefault();
                e.stopPropagation();
            });
            block.querySelector('button.search-submit-button').addEventListener('click', function(e) {
                e.preventDefault();
                filterTables(searchInput.value.toLowerCase());
            });
        }
        function filterTables(searchText) {
            if (searchText != '') {
                cells.forEach((cell, index) => {
                    cell.classList.remove('show', 'hide');
                    if (cell.innerHTML.toLowerCase().indexOf(searchText) !== -1) {
                        cell.classList.add('show');
                    }
                    else {
                        cell.classList.add('hide');
                    }
                });
                block.querySelectorAll('tbody tr').forEach((row, index) => {
                    row.classList.remove('hide', 'show');
                    if(row.querySelectorAll('td.show').length > 0) {
                        row.classList.add('show');
                    }
                    else {
                        row.classList.add('hide');
                    }
                });
                tables.forEach((table, index) => {
                    if(!table.querySelectorAll('tr.show').length > 0) {
                        table.closest('.table-section').classList.add('hide');
                        table.closest('.table-section').classList.remove('show');
                        if(block.querySelector('.table-shortcuts')) {
                            block.querySelector('.table-shortcuts li.slug-' + table.parentNode.querySelector('.table-title').getAttribute('id')).classList.add('hide');
                        }
                    }
                    else {
                        table.closest('.table-section').classList.remove('hide');
                        table.closest('.table-section').classList.add('show');
                        if(block.querySelector('.table-shortcuts')) {
                            block.querySelector('.table-shortcuts li.slug-' + table.parentNode.querySelector('.table-title').getAttribute('id')).classList.remove('hide');
                        }
                    }
                });
                let anchorLinks = block.querySelectorAll('.table-shortcuts li:not(.hide)');
                if(anchorLinks && anchorLinks.length > 0) {
                    let linkCount = anchorLinks.length;
                    anchorLinks[linkCount - 1].querySelector('span').classList.add('hide');
                }
                if(!block.querySelectorAll('.table-section.show').length > 0) {
                    block.querySelector('.wrapper').appendChild(noResultsElem);
                    if(block.querySelector('.table-shortcuts')) {
                        block.querySelector('ul.table-shortcuts').classList.add('hide');
                    }
                }
                else if(block.querySelector('.wrapper .no-results')) {
                    block.querySelector('.wrapper').removeChild(noResultsElem);
                    if(block.querySelector('.table-shortcuts')) {
                        block.querySelector('ul.table-shortcuts').classList.remove('hide');
                    }
                }
            }
            else {
                cells.forEach((cell, index) => {
                    cell.parentNode.classList.remove('show', 'hide');
                });
                tables.forEach((table, index) => {
                    table.closest('.table-section').classList.remove('hide');
                    table.closest('.table-section').classList.add('show');
                    if(block.querySelector('.table-shortcuts')) {
                        block.querySelector('.table-shortcuts li.slug-' + table.parentNode.querySelector('.table-title').getAttribute('id')).classList.remove('hide');
                    }
                });
                if(block.querySelector('.wrapper .no-results')) {
                    block.querySelector('.wrapper').removeChild(noResultsElem);
                }
                if(block.querySelector('.table-shortcuts')) {
                    block.querySelector('ul.table-shortcuts').classList.remove('hide');
                    block.querySelectorAll('.table-shortcuts li').forEach((link, index) => {
                        link.querySelector('span').classList.remove('hide');
                    });
                }
            }

        }
    });
}