const header = document.querySelector('header');
const body = document.querySelector('body');
const contentSection = document.querySelector('#content-section');

if(header) {
    let initialHeight = header.getBoundingClientRect().height;

    const menuBtn = header.querySelector('.menu-btn');
    
    menuBtn.addEventListener('click', function(e) {
        if(!header.classList.contains('menu-open')) {
            body.classList.add('no-scroll');
            header.classList.add('menu-open');
            contentSection.style.marginTop = initialHeight + 'px';
            setTimeout(function() {
                header.classList.add('anim');
            }, 100);
        }
        else {
            body.classList.remove('no-scroll');
            header.classList.remove('anim');
            setTimeout(function() {
                header.classList.remove('menu-open');
                contentSection.style.marginTop = '0px';
            }, 300);
        }
    });

    window.addEventListener('resize', function() {
        initialHeight = header.getBoundingClientRect().height;
    });
}