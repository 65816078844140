document.addEventListener("DOMContentLoaded", function() {
    // Smooth scroll to anchor links
    $('a').click(function(e) {
        if(this.href.substring(this.href.indexOf('#') + 1) != 'content-section') {
            scrollToAnchor(this.href, e, 100);
        }
    });

    window.scrollToAnchor = scrollToAnchor;
});

export const scrollToAnchor = function(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var currentPath = window.location.origin + window.location.pathname;
  /*   if (hashIndex > 0 && href.substring(0, hashIndex) !== currentPath) {
        return;
    } */

    var id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if ($el.length === 0){
        return;
    }

    $('html, body').animate({
        scrollTop: $el.offset().top - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
        //window.location.hash = id;
    }
}