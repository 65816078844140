const dropdownLists = document.querySelectorAll('section.dropdown-list');

if(dropdownLists.length > 0) {
    dropdownLists.forEach(list => {
        let dropdowns = list.querySelectorAll('.dropdown');
        dropdowns.forEach(element => {
            let dropdownToggle = element.querySelector('.dropdown-toggle');
            dropdownToggle.addEventListener('click', function() {
                if(!element.classList.contains('expanded')){
                    element.classList.add('expanded');
                    dropdownToggle.setAttribute('aria-expanded', true);
                }
                else {
                    element.classList.remove('expanded');
                    dropdownToggle.setAttribute('aria-expanded', false);
                }
            });
        });
    });
}