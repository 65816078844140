const single = document.querySelector('body.single-sprakspoersmal');

if(single) {
    let searchForm = single.querySelector('.search-questions');
    let inputField = searchForm.querySelector('input');
    let deleteBtn = searchForm.querySelector('form svg');
    
    if(inputField.value != '') {
        deleteBtn.classList.add('show');
    }

    inputField.addEventListener('keyup', function (e) {
        let searchText = e.target.value.toLowerCase();
        if(searchText != '') {
            deleteBtn.classList.add('show');
        }
        else {
            deleteBtn.classList.remove('show');
        }
    });
    deleteBtn.addEventListener('click', function(e) {
        inputField.value = '';
        deleteBtn.classList.remove('show');
    });
}