/*
 * Calculate visible view width.
 */

const maxWidth = 1488; // Remember to also change value in variables.scss
const horisontalPadding = 4 * 16; // Total horisontal padding on desktop * 16px

const setupBodyViewWidth = () => {
    let vw = window.innerWidth;
    if(maxWidth != null && vw >= (maxWidth)) {
        vw = maxWidth;
    }
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
setupBodyViewWidth();
window.addEventListener('resize', setupBodyViewWidth);
